<template>
    <div>
  

        <!-- 点击 Update data 后弹出 -->
            <div class="detail_cont">
                <h3 style="margin-bottom: 20px;">Financial</h3>
                <h2 class="fin_Tips">Due to the influence of local national laws, if a rating is needed for the company, users must provide the company's financial data or upload files of financial statements themselves. The platform will review the information manually and determine its authenticity before conducting the rating work.</h2>
            <div style="margin: 0 6%;">
                <el-form ref="Updateform" :model="form" label-width="220px" class="form_box" label-position="left">
                    <div class="flex_box flex_boxtwo">
                        <el-form-item label="Please provide more info:" class="fin_top_item">
                            <el-select v-model="form.pleaseProvideMoreInfo" placeholder="Select" class="fin_top_sel">
                                <el-option :label="item" :value="item" v-for="(item, index) in provideOptions" :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="Year:" class="fin_top_year">
                            <el-select v-model="form.year" placeholder="Select" class="fin_top_sel">
                                <el-option :label="item" :value="item" v-for="(item, index) in yearOptions" :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="fin_cont_box flex_center_between_box phoen_center_flex_warp">
                        <div class="fin_modu_box">
                            <div class="fin_modu">
                                <el-form-item label="Total Current Assets:">
                                    <el-input   autocomplete="off" v-model="form.totalCurrentAssets"></el-input>
                                </el-form-item>
                                <el-form-item label="Cash & Bank:">
                                    <el-input   autocomplete="off" v-model="form.cashBank"></el-input>
                                </el-form-item>
                                <el-form-item label="Account receivable:">
                                    <el-input   autocomplete="off" v-model="form.accountReceivable"></el-input>
                                </el-form-item>
                                <el-form-item label="Advances to Suppliers:">
                                    <el-input   autocomplete="off" v-model="form.advancesToSuppliers"></el-input>
                                </el-form-item>
                                <el-form-item label="Inventory:">
                                    <el-input   autocomplete="off" v-model="form.inventory"></el-input>
                                </el-form-item>
                                <el-form-item label="To be appointed expense:">
                                    <el-input   autocomplete="off" v-model="form.toBeAppointedExpense"></el-input>
                                </el-form-item>
                            </div>
                            <div class="fin_modu">
                                <el-form-item label="Total non-current assets:">
                                    <el-input   autocomplete="off" v-model="form.totalNonCurrentAssets"></el-input>
                                </el-form-item>
                                <el-form-item label="Long-term investment:">
                                    <el-input   autocomplete="off" v-model="form.longTermInvestment"></el-input>
                                </el-form-item>
                                <el-form-item label="Fixed assets net value:">
                                    <el-input   autocomplete="off" v-model="form.fixedAssetsNetValue"></el-input>
                                </el-form-item>
                                <el-form-item label="Projects under construction:">
                                    <el-input   autocomplete="off" v-model="form.projectsUnderConstruction"></el-input>
                                </el-form-item>
                                <el-form-item label="Long-term to be appointed expenise:">
                                    <el-input   autocomplete="off" v-model="form.longTermToBeAppointedExpenise"></el-input>
                                </el-form-item>
                                <el-form-item label="Deferred tax debit:">
                                    <el-input   autocomplete="off" v-model="form.deferredTaxDebit"></el-input>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="fin_modu_box">
                            <div class="fin_modu">
                                <el-form-item label="Total current liabilities:">
                                    <el-input v-model="form.totalCurrentLiabilities"></el-input>
                                </el-form-item>
                                <el-form-item label="Account payable:">
                                    <el-input v-model="form.accountPayable"></el-input>
                                </el-form-item>
                                <el-form-item label="Advances from clients:">
                                    <el-input v-model="form.advancesFromClients"></el-input>
                                </el-form-item>
                                <el-form-item label="Salaries payable:">
                                    <el-input v-model="form.salariesPayable"></el-input>
                                </el-form-item>
                                <el-form-item label="Taxes payable:">
                                    <el-input v-model="form.taxesPayable"></el-input>
                                </el-form-item>
                                <el-form-item label="Other accounts payable:">
                                    <el-input v-model="form.otherAccountsPayable"></el-input>
                                </el-form-item>
                            </div>
                            <div class="fin_modu">
                                <el-form-item label="Total equity:">
                                    <el-input v-model="form.totalEquity"></el-input>
                                </el-form-item>
                                <el-form-item label="Paid up capital:">
                                    <el-input v-model="form.paidUpCapital"></el-input>
                                </el-form-item>
                                <el-form-item label="Capital reserves:">
                                    <el-input v-model="form.capitalReserves"></el-input>
                                </el-form-item>
                                <el-form-item label="Surplus reserves:">
                                    <el-input v-model="form.surplusReserves"></el-input>
                                </el-form-item>
                                <el-form-item label="Undistrbuted porfit:">
                                    <el-input v-model="form.undistrbutedPorfit"></el-input>
                                </el-form-item>
                                <el-form-item label="Total assets:">
                                    <el-input v-model="form.totalAssets"></el-input>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="fin_modu_box">
                            <div class="fin_modu last">
                                <el-form-item label="Turnover:">
                                    <el-input v-model="form.turnover"></el-input>
                                </el-form-item>
                                <el-form-item label="Cost of goods sold:">
                                    <el-input v-model="form.costOfGoodsSold"></el-input>
                                </el-form-item>
                                <el-form-item label="Sales tax:">
                                    <el-input v-model="form.salesTax"></el-input>
                                </el-form-item>
                                <el-form-item label="Gross profit:">
                                    <el-input v-model="form.grossProfit"></el-input>
                                </el-form-item>
                                <el-form-item label="Other operating profils:">
                                    <el-input v-model="form.otherOperatingProfils"></el-input>
                                </el-form-item>
                                <el-form-item label="Sales expense:">
                                    <el-input v-model="form.salesExpense"></el-input>
                                </el-form-item>
                                <el-form-item label="Management expense:">
                                    <el-input v-model="form.managementExpense"></el-input>
                                </el-form-item>
                                <el-form-item label="Finance expense:">
                                    <el-input v-model="form.financeExpense"></el-input>
                                </el-form-item>
                                <el-form-item label="Operating profit:">
                                    <el-input v-model="form.operatingProfit"></el-input>
                                </el-form-item>
                                <el-form-item label="Non-operating income:">
                                    <el-input v-model="form.nonOperatingIncome"></el-input>
                                </el-form-item>
                                <el-form-item label="Profit before tax:">
                                    <el-input   autocomplete="off" v-model="form.profitBeforeTax"></el-input>
                                </el-form-item>
                                <el-form-item label="Income tax:">
                                    <el-input   autocomplete="off" v-model="form.incomeTax"></el-input>
                                </el-form-item>
                                <el-form-item label="Net income:">
                                    <el-input   autocomplete="off" v-model="form.netIncome"></el-input>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="fin_title">Business</div>
                    <div class="bus_box">
                        <el-form-item label="No.of employees:">
                            <el-input   autocomplete="off" v-model="form.noOfEmployees"></el-input>
                        </el-form-item>
                        <el-form-item label="Core management:">
                            <el-input   autocomplete="off" v-model="form.coreManagement"></el-input>
                        </el-form-item>
                        <el-form-item label="Core business activites:">
                            <el-input   autocomplete="off" v-model="form.coreBusinessActivites" type="textarea" :rows="5"></el-input>
                        </el-form-item>
                    </div>
                    <div class="bus_box2">
                        <el-form-item label="Int' Business ">
                            <el-select v-model="form.intBusiness" placeholder="Select">
                                <el-option :label="item" :value="item" v-for="(item, index) in intBusOptions" :key="index"></el-option>
                            </el-select>
                            <el-input   autocomplete="off" class="m_l" v-model="form.intBusiness1" placeholder="Please describe your activites"></el-input>
                            <el-input   autocomplete="off" class="m_l" v-model="form.activites2" placeholder="Please describe your activites"></el-input>
                            <el-input   autocomplete="off" class="m_l" v-model="form.intBusiness3" placeholder="Please list countries you do busine"></el-input>
                        </el-form-item>
                        <el-form-item label="Core competency :">
                            <el-checkbox-group v-model="form.coreCompetency" class="check_box">
                                <el-checkbox v-for="competency in CompetencyList" :label="competency" :key="competency">{{ competency }}</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </div>
                    <el-form-item label="Business outlook:" class="out_item">
                        <el-select v-model="form.businessOutlook" placeholder="Select">
                            <el-option :label="item" :value="item" v-for="(item, index) in busOptions" :key="index"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Upload files:">
                        <el-upload class="upload-demo" action="fakeaction" ref="uploadFile" :http-request="hanldUploadFile" :before-remove="handleRemove"   v-model="form.uploadFiles" :file-list="filesList">
                            <el-button slot="trigger">Upload</el-button>
                        </el-upload>
                    </el-form-item>
                    <div class="btn_box">
                        <!-- <el-button class="btn_cancel" @click="cancel()">Cancel</el-button> -->

                        <el-button class="btn_export" @click="onSubmit()">Submit</el-button>
                    </div>
                </el-form>
            </div>
            </div>

  
    </div>
</template>
<script>
import { getReportError, getUpdateDataCompare, uploadFile, deleteFile } from '@/api/companyApi.js';
import crypto from '@/utils/crypto';
export default {
    props: {

        powerStatus: {
            type: Boolean,
            default: false,
        },
        Company: {
            type: Object,
            default: {},
        },
     
    },
    data() {
        return {
            // newDialog: true,
            tabName: 'Reportmycompany',
            finDialog: false,
            reportDialog: false,
            newDialogtwo: false,
            form: {
                pleaseProvideMoreInfo: '',
                year: new Date().getFullYear(),
                totalCurrentAssets: '',
                cashBank: '',
                accountReceivable: '',
                advancesToSuppliers: '',
                inventory: '',
                toBeAppointedExpense: '',
                totalNonCurrentAssets: '',
                longTermInvestment: '',
                fixedAssetsNetValue: '',
                projectsUnderConstruction: '',
                longTermToBeAppointedExpenise: '',
                deferredTaxDebit: '',
                // liabilities: '',
                totalCurrentLiabilities:'',
                accountPayable: '',
                advancesFromClients: '',
                salariesPayable: '',
                taxesPayable: '',
                otherAccountsPayable: '',
                totalEquity: '',
                paidUpCapital: '',
                capitalReserves: '',
                surplusReserves: '',
                undistrbutedPorfit: '',
                totalAssets: '',
                turnover: '',
                costOfGoodsSold: '',
                salesTax: '',
                grossProfit: '',
                otherOperatingProfils: '',
                salesExpense: '',
                managementExpense: '',
                financeExpense: '',
                operatingProfit: '',
                nonOperatingIncome: '',
                profitBeforeTax: '',
                incomeTax: '',
                netIncome: '',
                noOfEmployees: '',
                coreManagement: '',
                coreBusinessActivites: '',
                intBusiness3: '',
                intBusiness1: '',
                intBusiness2: '',
                intBusiness: '',
                coreCompetency: [],
                businessOutlook: '',
                uploadFiles: [],
              
                // filesList:[]
            },
            type: '701',
            filesList: [],
            provideOptions: ['CNY', 'EURO', 'USD'],
            yearOptions: [], //['2022', '2021', '2020', '2019', '2018', '2017', '2016', '2015', '2014', '2013', '2012', '2011', '2010', '2009', '2008'],
            busOptions: ['Good', 'Not optimistic', 'Unsure'],
            CompetencyList: ['Patent', 'Trademark', 'Copyright', 'Software copyright', 'High-tech'],
            intBusOptions: ['Yes', 'No'],
            subjectOptions: ['COMPANY SNAPSHOT','COMPANY PROFILE','MANAGEMENT', 'OWNERSHIP', 'BUSINESS OPERATIONS', 'PERFORMANCE','IMPORT&EXPORT'],
            disabledReport1: true,
            disabledReport2: true,
            disabledReport: true,
            reportForm: { subject: '', demand: '' },
            addReportList: [{ subject: '', demand: '' ,uploadFiles: [],filesList:[]}],
            checked: false,
        };
    },
    computed: {
  

    },
    created() {
        this.getYears();
    },
    methods: {
        // 文件上传限制
        handleExceed(){
            this.$message.warning(`The current limit is to select 3 files, with a total of ${this. form. uploadFiles. length+1} files selected`);
        },
   
    // }
        // },
        async setData() {
            let copyCompany = JSON.parse(localStorage.getItem('CompanyBase')).companyName
            const params = {
                        companyName:copyCompany ,
                        companyId: this.$route.query.id3a,
                        demand: JSON.stringify(this.form),
                        type: this.checked ? 701 : 7,
                    };
                    let res = await getUpdateDataCompare(params);
                    if (res && res.code) {
                        this.$message({
                            message: res.msg,
                            center: true,
                            type: 'success',
                        });
                    }
   
        },
        //获取年 2008-2023
        getYears() {
            const loadYear = new Date().getFullYear();
            const n = loadYear - 2007;
            for (var i = 0; i < n; i++) {
                const year = loadYear - i;
                this.yearOptions.push(year);
            }
        },
  
    
    
    
        //删除文件
        handleRemove(file,fileList) {
            let index = fileList.findIndex(item => item.name == file.name);
            const str = this.form.uploadFiles[index];
            let i = str.lastIndexOf('/');
            let result = str.substr(i + 1, str.length);
     
          this.form.uploadFiles.splice(index, 1)
        //   this.filesList.splice(index, 1)
         
            let params = { fileName: result };
            deleteFile(params).then(res => {
                if (res && res.code) {
                    this.$message({
                        message: res.msg,
                        center: true,
                        type: 'success',
                    });
                }
            });
        },
   
        //上传文件
        hanldUploadFile(params) {
            let file = params.file;
            let form = new FormData();
            form.append('file', file);
            uploadFile(form).then(res => {
                if (res && res.code) {
                    this.form.uploadFiles.push(res.data);
                    // this.form.filesList.push({name:file.name,url:res.data});
                } else {
                    this.$message({
                        message: res.msg,
                        center: true,
                        type: 'error',
                    });
                    let uid = file.uid;
                    let idx = this.$refs.uploadFile.uploadFiles.findIndex(item => item.uid === uid);
                    this.$refs.uploadFile.uploadFiles.splice(idx, 1);
                    // this.form.filesList.splice(idx, 1)
             
                }
            });
        },
   
        //提交update
        onSubmit() {
                this.setData();
        },
        //取消update
     

    },
};
</script>
<style scoped>
.tab_box /deep/ .el-tabs__header {
    margin-bottom: 0.7142857142857143rem;
}
.el-tabs--card > .el-tabs__header {
    border: none;
}
.tab_box /deep/ .el-tabs__header {
    width: 100%;
    box-shadow: 0rem 0.14285714285714285rem 0.42857142857142855rem 0.07142857142857142rem rgba(10, 33, 57, 0.14);
    border-radius: 1rem;
}
.tab_box /deep/ .el-tabs__header {
    padding: 0.7857142857142857rem 1.1428571428571428rem;
    display: none;
}
.result_tab {
    padding: 1.4285714285714286rem;
}
.tab_box /deep/ .el-tabs__nav-wrap {
    width: 100%;
    height: 2rem;
    background: #F5F5F5;
    border-radius: 0.5714285714285714rem;
    border: 0.07142857142857142rem solid #CCCCCC;
}
.tab_box /deep/ .el-tabs__nav {
    width: 100%;
    border: none;
    padding: 0.14285714285714285rem 2rem;
    display: flex;
    justify-content: space-between;
}
.tab_box /deep/ .el-tabs__item {
    padding: 0 1.0714285714285714rem;
    width: 11.428571428571429rem;
    height: 1.4285714285714286rem;
    line-height: 1.4285714285714286rem;
    font-size: 0.8571428571428571rem;
    color: #8497ab;
    border: none;
    text-align: center;
}
.tab_box /deep/ .el-tabs__item:last-child {
    padding-right: 1.0714285714285714rem !important;
}
.tab_box /deep/ .el-tabs__item.is-active {
    background: #ff7600;
    box-shadow: 0rem 0.07142857142857142rem 0.14285714285714285rem 0rem #c44306;
    border-radius: 0.2857142857142857rem;
    font-family: 'DMSans Bold';
    color: #ffffff;
}

.tab_box /deep/.el-tabs__content {
    width: 100%;

    background: #ffffff;
 
}


.fin_title {
    font-family: 'DMSans Bold';
    margin-bottom: 2.142857142857143rem;
    line-height: 1.7142857142857142rem;
font-size: 1.4285714285714286rem;
color: #1A2332;
}
.form_box /deep/ .el-input__inner {
    width: 100%;
    min-height: 2.857142857142857rem;
    line-height: 2.857142857142857rem;
    background: #ffffff;
    border-radius: 0.5714285714285714rem;
    border: 0.07142857142857142rem solid #CCCCCC;
}
.fin_top_sel /deep/ .el-input__inner {
    width: 19.714285714285715rem;
    min-height: 2.857142857142857rem;
    background: #FFFFFF;
    border-radius: 0.5714285714285714rem;
    border: 0.07142857142857142rem solid #CCCCCC;
    font-size: 1.28571428571428rem;
    padding: 0.35714285714285715rem 0.7142857142857143rem !important;
}
.fin_top_year /deep/.el-form-item__label,
.fin_top_item /deep/.el-form-item__label {
    width: auto !important;
}
.form_box /deep/.el-form-item__label {
    padding-right: 0.7142857142857143rem;
    font-size: 1.28571428571428rem;
}
.fin_top_item /deep/ .el-form-item__content {
    margin-left: 15.714285714285714rem !important;
}
.fin_top_year /deep/ .el-form-item__content {
    margin-left: 5rem !important;
}
.fin_top_year {
    margin-left: 2.857142857142857rem;
}
.form_box /deep/ .el-form-item__label {
    color: #1A2332  !important;
}
.form_box /deep/ .el-select .el-icon-arrow-up:before {
    color: #8497ab !important;
}
.fin_cont_box {
    margin-bottom: 0.7142857142857143rem;
}
.fin_modu_box{
    width: 32%;
}
.fin_modu {
    /* width: 28.571428571428573rem; */
    /* height: 22.857142857142858rem; */
    padding: 1.4285714285714286rem 0.7142857142857143rem;
    margin-bottom: 1.4285714285714286rem;
    background: #F5F5F5;
    border-radius: 0.5714285714285714rem;
    border: 0.07142857142857142rem solid #CCCCCC;
}
.fin_modu.last {
    /* height: 47.214285714285715rem; */
}
.form_box /deep/ .el-form-item__content {
    line-height: 2.857142857142857rem;
}
.form_box /deep/ .el-form-item__label {
    display: flex;
    align-items: center;
    height: 2.857142857142857rem;
    line-height: 2.857142857142857rem;
        word-break: keep-all;
        margin-bottom: 0rem;
}

.form_box /deep/ .el-form-item {
    margin-bottom: 1.4285714285714286rem;
}
.bus_box /deep/ .el-textarea__inner,
.bus_box /deep/ .el-input__inner {
    width:100%;
}
.bus_box2 /deep/ .el-form-item__content {
    display: flex;
}
.out_item /deep/ .el-input__inner,
.bus_box2 /deep/ .el-input__inner {
    width: 15.285714285714286rem;
    height: 2.4285714285714284rem;
}
.bus_box2 /deep/ .m_l .el-input__inner {
    margin-left: 1.1428571428571428rem;
}
.form_box /deep/ .out_item {
    margin-bottom: 1.4285714285714286rem;
}
.form_box /deep/ .el-input__icon {
    height: auto;
}
.upload-demo /deep/ .el-button {
  
    padding: 0.7142857142857143rem 1.4285714285714286rem;
  background: #EEEEEE;
    border-radius: 0.7142857142857143rem;
    border: 0.07142857142857142rem solid #EEEEEE;
    color: #1290c9;
    font-size: 1.2857142857142858rem;
}
.check_box /deep/ .el-checkbox__inner {
    width: 1.4285714285714286rem;
    height: 1.4285714285714286rem;
    border-radius: 0.2857142857142857rem;
    border: 0.07142857142857142rem solid #8497ab;
}
.report_form /deep/ .el-form-item__label,
.check_box /deep/ .el-checkbox__label {
    font-size: 1.28571428571428rem;
color: #1A2332;
}
.check_box /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.check_box /deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #1290c9;
    border-color: #1290c9;
}
.check_box /deep/ .el-checkbox__inner::after {
    height: 0.8571428571428571rem;
    left: 0.35714285714285715rem;
    top: 0rem;
    width: 0.42857142857142855rem;
}
.btn_box {
    text-align: center;
    margin-top: 1.7142857142857142rem;
}
.btn_cancel { 
background: #8497AB;
border-radius: 0.7142857142857143rem;
color: #ffffff;
height: auto;
}
 .btn_export { 
border-radius: 0.7142857142857143rem;
height: auto;
}
.btn_box button + button {
    margin-left: 2.857142857142857rem;
}
.rep_list {
    padding-bottom: 0.7142857142857143rem;
}





.report_form_box {
    /* padding: 0.7142857142857143rem 0.7142857142857143rem 0; */
    /* margin-top: 3rem; */
    margin-bottom: 1.4285714285714286rem;
    /* background: #F5F5F5; */
    border-radius: 0.5714285714285714rem;
    /* border: 0.07142857142857142rem solid #CCCCCC; */
}
.report_form /deep/ .el-input__inner {
    /* width: 30rem; */
    height: 2.4285714285714284rem;
    line-height: 2.4285714285714284rem;
}
.report_form /deep/ .el-form-item {
    margin-bottom: 1.0714285714285714rem;
}
.limit_box /deep/ .el-textarea__inner {
    height: 10.714285714285714rem;
    /* box-shadow: 0rem 0.14285714285714285rem 1rem 0rem rgba(0,0,0,0.12); */
}
.report_item {
    /* margin-top: 3.4285714285714284rem; */
}
</style>
<style>
.dialog_lg.el-dialog {
    width: 82.14285714285714rem;
    /* max-height: 85vh; */
    background: #ffffff;
}
.detail_cont .el-upload-list__item {
    display: flex;
}
.el-upload-list__item-status-label {
    display: inline-block;
}
.el-upload-list__item:hover .el-upload-list__item-status-label {
    display: none;
}
.detail_cont .el-upload-list__item-status-label,
.detail_cont .el-upload-list__item .el-icon-close {
    position: static;
}
.detail_cont .el-upload-list__item .el-icon-close {
    line-height: 1.8571428571428572rem;
}
.detail_cont_text {
    /* 解决英文截断问题 */
    word-break: keep-all;
    text-align: justify;
  font-size: 1.2857142857142858rem;
color: #1A2332;
line-height: 2.142857142857143rem;
/* padding: 0px !important; */

}
.detail_cont_text p {
}
.dialog_detail_check .el-checkbox .el-checkbox__label {
    width: 28.571428571428573rem;
    display: inline-block;
    /* overflow: hidden;
    text-overflow: ellipsis; */
    position: relative;
    /* top: 5px; */
    font-size: 1.2857142857142858rem;
color: #1A2332;
}
.dialog_detail_title{
    font-size: 1.4285714285714286rem;
color: #1A2332;
/* line-height: 27px; */
/* line-height: 40px; */
margin-bottom: 1.4285714285714286rem !important;
font-family: 'DMSans Bold';
}
.dialog_detail_check .el-checkbox{
display: flex;
align-items: center;
white-space:normal
}
/deep/ .el-checkbox__input {
    white-space: normal;
}
.fin_Tips{
    font-family: 'DMSans Bold';
    margin-bottom: 2.142857142857143rem;
    line-height: 1.7142857142857142rem;
    font-size: 1.4285714285714286rem;
    color: #1A2332;
}
</style>
